$small: 720px;
$medium: 1200px;

.wrapper {
  position: relative;
  z-index: 10;
  background-color: #101010;
  .container {
    @media screen and (max-width: $medium) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      align-content: center;
    }

    .h2 {
      font-size: 24px;
      font-weight: 600;
      padding-left: 40px;
      padding-top: 100px;

      @media screen and (max-width: $medium) {
        padding-left: 80px;
      }

      @media screen and (max-width: $small) {
        padding-top: 40px;
        padding-left: 40px;
      }
    }
  }

  .total {
    border: solid 1px #ffffff10;
    background-color: #101010;
    align-self: center;
    padding: 0 35px 35px 35px;

    @media screen and (max-width: $medium) {
      width: 90%;
    }

    .p {
      margin-bottom: 10px;
      font-size: 16px;
      color: #eee;
      font-weight: 500;
      padding-top: 35px;
    }

    .bar {
      width: 100%;
      height: 5px;
      background: rgba(255, 255, 255, 0.1);

      .progress {
        height: 100%;
      }
    }
  }
}
