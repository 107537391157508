$small: 720px;
$medium: 1200px;
.wrapper {
  height: 140px;
  position: fixed;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .container {
    width: 95%;
    display: flex;
    justify-content: space-between;
    height: 40px;
    overflow: hidden;

    @media screen and (max-width: $medium) {
      width: 88%;
    }

    @media screen and (max-width: $small) {
      width: 85%;
    }

    .a {
      font-size: 16px;
      line-height: 2.5;
      font-family: "Roboto";
      font-weight: 600;
      letter-spacing: 0;
      color: #ffffff80;
      text-decoration: none;

      .cont {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .span {
          display: block;
        }

        .strong {
          font-weight: 700;
          color: #fff;
        }
      }
    }
  }
}

.links {
  display: flex;
  justify-content: space-between;
  gap: 40px;

  @media screen and (max-width: $medium) {
    display: none;
  }
}

.hamburger {
  display: none;
  margin-top: 10px;
  position: relative;
  z-index: 30;

  @media screen and (max-width: $medium) {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }

  .line {
    width: 30px;
    height: 2px;
    background-color: white;
  }
}
