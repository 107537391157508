$small: 720px;
$medium: 1200px;

.wrapper {
  position: relative;
  z-index: 10;
  background-color: #101010;

  .container {
    @media screen and (max-width: $medium) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      align-content: center;
    }

    .total {
      border: solid 1px #ffffff10;
      background-color: #101010;
      align-self: center;
      padding: 35px;

      @media screen and (max-width: $medium) {
        width: 90%;
      }
    }
  }
}

.h2 {
  font-size: 24px;
  font-weight: 600;
  padding-left: 40px;
  padding-top: 100px;

  @media screen and (max-width: $medium) {
    padding-left: 80px;
  }

  @media screen and (max-width: $small) {
    padding-top: 40px;
    padding-left: 40px;
  }
}

.cont {
  border: solid 1px #ffffff10;
  padding: 10px;
  min-height: 210px;
}

.user {
  margin: 0 0 7px 0;
  color: #eee;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
}

.where {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.desc {
  font-size: 16px;
  line-height: 1.5;
  color: #bbb;
}

.flag {
  display: inline;
  height: 10px;
}
