$small: 720px;
$medium: 1200px;

.button {
  // margin-bottom: 30px;
  width: 200px;
  height: 40px;
  background: none;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  position: relative;
  z-index: 12;
  display: inline-block;

  .fake {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    position: absolute;
    left: 0px;
    top: -80px;
    z-index: -10;
  }
}
