.wrapper {
  top: 0;
  height: 100vh;
  width: 100%;
  background-image: url(../../../public/images/header-2.webp);
  background-position: center;
  background-size: cover;
  position: fixed;
  z-index: -10;
}
