$small: 720px;
$medium: 1200px;

.wrapper {
  position: relative;
  z-index: 10;
  background-color: #101010;
  .container {
    @media screen and (max-width: $medium) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      align-content: center;
    }

    .h2 {
      font-size: 24px;
      font-weight: 600;
      padding-left: 40px;
      padding-top: 100px;

      @media screen and (max-width: $medium) {
        padding-left: 80px;
      }

      @media screen and (max-width: $small) {
        padding-top: 40px;
        padding-left: 40px;
      }
    }

    .content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      justify-content: center;

      @media screen and (max-width: $small) {
        flex-direction: column;
      }

      .img {
        width: 160px;

        margin: 34px 0;

        @media screen and (max-width: $medium) {
          width: 120px;
        }

        @media screen and (max-width: $small) {
          width: 100px;
        }
      }
      .text {
        padding-top: 25px;
        padding-left: 20px;
        text-align: justify;
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        @media screen and (max-width: $medium) {
        }

        @media screen and (max-width: $small) {
          padding-top: 15px;

          align-items: center;
          text-align: center;
          width: 75%;
          padding-left: 0px;
        }

        .aboutText {
          color: #bbb;
          line-height: 1.5;
          font-size: 16px;
          font-weight: 400;
          padding-bottom: 10px;
        }

        .details {
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 30px;
          justify-content: space-between;
          align-items: center;

          @media screen and (max-width: $small) {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
          }

          .p {
            flex: 0 1 50%;
            padding-top: 14px;
            color: white;
            font-size: 16px;

            .span {
              color: #bbbbbb;
            }
          }
        }
      }
    }
  }
  .button {
    margin-bottom: 30px;
    width: 200px;
    height: 40px;
    background: none;
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    position: relative;
    z-index: 12;

    .fake {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      position: absolute;
      left: 0px;
      top: -80px;
      z-index: -10;
    }
  }
  .total {
    border: solid 1px #ffffff10;
    background-color: #101010;
    text-align: center;
    align-self: center;
    padding-bottom: 30px;

    @media screen and (max-width: $medium) {
      width: 90%;
    }
  }
}
