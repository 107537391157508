$small: 720px;
$medium: 1200px;

.wrapper {
  overflow: hidden;
}

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  @media screen and (max-width: $medium) {
    margin-left: 10%;
  }
}

.revealContainer {
  display: flex;
}

.revealColumn {
  display: inline-block;
  width: 1px;
  background-color: white;
}

.revealContent {
  overflow: hidden;
  padding: 0px 16px;
}

.h2 {
  width: 100%;
  padding-left: 20px;

  color: rgb(255, 255, 255);
  font-size: 35px;
  line-height: 100px;
  font-weight: 500;

  @media screen and (max-width: 1322px) {
    font-size: 35px;
  }

  @media screen and (max-width: $medium) {
    font-size: 30px;
  }

  @media screen and (max-width: $small) {
    font-size: 20px;
  }
}

h2 {
  color: rgb(255, 255, 255);
  line-height: 100px;
  font-weight: 500;
}

h1 {
  color: rgb(255, 255, 255);

  // margin-right: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 560px;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.5;
  padding-left: 35px;
  padding-bottom: 50px;

  @media screen and (max-width: $medium) {
    padding-left: 50px;
  }

  @media screen and (max-width: $small) {
    font-size: 17px;
    padding-right: 70px;
    padding-left: 30px;
    padding-bottom: 30px;
  }
}

.hi {
  color: white;
  font-size: 28px;

  @media screen and (max-width: $medium) {
    font-size: 28px;
  }

  @media screen and (max-width: $small) {
    font-size: 20px;
  }
}

.iam {
  font-size: 64px;
  font-weight: 700;
  color: white;

  @media screen and (max-width: $medium) {
    font-size: 50px;
  }

  @media screen and (max-width: $small) {
    font-size: 40px;
  }
  @media screen and (max-width: 490px) {
    font-size: 30px;
  }
}

.fullstack {
  display: inline-block;
  font-weight: 700;
  font-size: 64px;
  margin-right: 15px;

  @media screen and (max-width: $medium) {
    font-size: 50px;
  }

  @media screen and (max-width: $small) {
    font-size: 40px;
  }
  @media screen and (max-width: 490px) {
    font-size: 30px;
  }
}
.developer {
  display: inline-block;
  font-size: 64px;
  font-weight: 700;
  color: white;
  // margin-left: 15px;

  @media screen and (max-width: $medium) {
    font-size: 50px;
  }

  @media screen and (max-width: $small) {
    font-size: 40px;
  }
  @media screen and (max-width: 490px) {
    font-size: 30px;
  }
}
