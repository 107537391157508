$small: 720px;
$medium: 1200px;

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 25;
  background-color: #101010;
  display: flex;
  flex-direction: column;
  align-items: center;

  .a {
    color: #bbb;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 50px;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
  }
}
