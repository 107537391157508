$small: 720px;
$medium: 1200px;

.wrapper {
  display: flex;
  justify-content: center;
  .container {
    width: 60%;
    @media screen and (max-width: $medium) {
      width: 100%;
    }

    .lines {
      top: 0;
      position: absolute;
      z-index: -5;
      display: flex;
      width: 60%;
      height: 100%;
      @media screen and (max-width: $medium) {
        width: 100%;
      }

      .col {
        flex: 0 1 100%;
        border: solid 1px #ffffff10;
        border-top-style: none !important;
        border-bottom-style: none !important;
      }

      .col2 {
        @media screen and (max-width: $small) {
          display: none;
        }
      }

      // .col3 {
      // }
    }
  }
}
