$small: 720px;
$medium: 1200px;

.wrapper {
  position: relative;
  z-index: 10;
  background-color: #101010;

  .container {
    @media screen and (max-width: $medium) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-content: center;
    }

    .h2 {
      font-size: 24px;
      font-weight: 600;
      padding-left: 40px;
      padding-top: 100px;
      align-self: flex-start;

      @media screen and (max-width: $medium) {
        padding-left: 80px;
      }

      @media screen and (max-width: $small) {
        padding-top: 40px;
        padding-left: 40px;
      }
    }

    .category {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 40px;
      background-color: #101010;
      border: solid 1px #ffffff10;
      padding-left: 40px;
      height: 100px;
      font-size: 16px;
      color: #eeeeee;
      font-weight: 400;

      .h3 {
        cursor: pointer;
      }

      @media screen and (max-width: $medium) {
        width: 90%;
      }
      @media screen and (max-width: $small) {
        font-size: 14px;
      }
    }
  }
}

.total {
  min-height: 600px;
  padding-bottom: 50px;

  @media screen and (max-width: $medium) {
    width: 90%;
  }
}

.subCategory {
  align-self: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  flex-wrap: wrap;

  @media screen and (max-width: $medium) {
    width: 100%;
  }

  @media screen and (max-width: $small) {
    flex-direction: column;
  }
}
