$small: 720px;
$medium: 1200px;

.wrapper {
  margin-top: 50px;
  position: relative;
  width: 45%;
  min-width: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 16/9;
  text-decoration: none;

  @media screen and (max-width: $small) {
    width: 100%;
  }

  .img {
    height: 100%;
    position: absolute;
    z-index: 35;
  }

  .fakeBg {
    position: absolute;
    z-index: 36;
    border-radius: 50%;
    width: 800px;
    height: 800px;
  }

  .text {
    position: relative;
    z-index: 37;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;

    .title {
      font-size: 14px;
      color: #101010;
      opacity: 60%;
      font-weight: 400;
    }

    .desc {
      font-size: 24px;
      color: #101010;
      font-weight: 500;
      padding: 0 30px;

      @media screen and (max-width: $medium) {
        font-size: 18px;
      }
    }
  }
}
